@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');


@font-face {
    font-family: 'Cocogoose Pro SemiLight Trial';
    src: url('../src/fonts/Cocogoose\ Pro\ SemiLight\ Trial.eot');
    src: url('../src/fonts/Cocogoose Pro SemiLight Trial.eot?#iefix') format('embedded-opentype'),
         url('../src/fonts/Cocogoose Pro SemiLight Trial.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'CocogoosePro-SemiLight';
    src: url('../src/fonts/CocogoosePro-SemiLight.svg#CocogoosePro-SemiLight') format('svg'),
         url('../src/fonts/CocogoosePro-SemiLight.ttf') format('truetype'),
         url('../src/fonts/CocogoosePro-SemiLight.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }
  
  

body, html {
    height: 100%;
}
body {    
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: 'Roboto', sans-serif;
}

.btn-primary {
    text-transform: uppercase;
    padding: 11px 20px;
    background-color: #1976D2;
    box-shadow: 0 6px 6px rgb(0 0 0 / 10%) !important;
    font-size: 14px;
}
.btn-primary:hover {
    background-color: #1976D2;
}

/************* Autofill Form CSS */

input:-webkit-autofill,
input:-webkit-autofill:enabled,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-transition-delay: 9999s;
}

input:-internal-autofill-selected,
input::-internal-autofill-previewed{
    -webkit-transition-delay: 9999s;
    transition-delay: 9999s;
}

/************* Input date CSS ******/

/* input[type=date]:required:invalid::-webkit-datetime-edit {
    color: transparent;
}
input[type=date]:focus::-webkit-datetime-edit {
    color: black !important;
}

input[type="date"]
{
    display:block;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    min-height: 1.2em;
} */


.form-field {
    position: relative;
  }
  
  /* Adjust the alignment of the date picker */
.flatpickr-calendar {
    /* Your custom styles here */
    position: absolute !important; /* or relative, depending on your layout needs */
    left: 40% !important; /* Adjust as needed to align with the text field */
    top: 78% !important; /* Adjust as needed to align with the text field */
    z-index: 1000 !important; /* Ensure it's above other elements */
}



 /********** Captcha CSS Changes  ********/
#recaptcha-container
{
    transform: scale(0.77); 
    -webkit-transform: scale(0.77); 
    transform-origin: 0 0; 
    -webkit-transform-origin: 0 0;
}


.g-recaptcha-bubble-arrow + div
{
    position: fixed !important;
    left: 51.5%;
    top: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
}
.g-recaptcha-bubble-arrow
{
    display: none;
}

/* ************************************************************
***************************************************************
--- Sign Up Page Start ---
***************************************************************
************************************************************ */

.sign-up-sec.main-bg {
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 100vh;
    width: 100%;
    padding: 80px 20px;
    /* height: auto; */
    display: flex;
    align-items: center;
}
.sign-up-sec .white-wrapper {
    background-color: #fff;
    padding: 35px 35px 50px 30px;
    margin: 0 auto;
    max-width: 480px;    
    box-shadow: 0px 3px 10px #00000033;
    border-radius: 0.5%;    
    width: 100%;
}

.sign-up-sec .snacks-logo {
    margin-bottom: 10%;
}
.sign-up-sec .snacks-logo img {
    max-width: 40%;
    width: 100%;
}
.sign-up-sec .snacks-title {
    font-family: 'CocogoosePro-SemiLight';
    font-size: 18px;
    line-height: 24px;
    color: #1E1E1E;
    font-weight: normal;
    margin-bottom: 7%;
}

.sign-up-sec .sub-title {
    font-size: 18px;
    color: #1E1E1E;
    font-weight: 400;
    line-height: 22px;
    margin-bottom: 5%;
}

.sign-up-sec .before-sign-in {
    font-size: 16px;
    color: #1E1E1E;
    font-weight: 400;
    line-height: 22px;
    margin-bottom: 5%;
}

.sign-up-sec .before-sign-in a{    
    font-weight: 700;
    color: #1E1E1E;
    text-decoration: underline;
    padding-left: 10px;
}

.sign-up-sec .pwd-requirement {
    /* margin-top: 10px; */
    padding-bottom: 4%;
    border-bottom: 1px solid #707070;
    margin-bottom: 4%;
}

.sign-up-sec .pwd-requirement p {
    margin-bottom: 5px;
}

.sign-up-sec .pwd-requirement li {
    color: #1E1E1E;
    font-size: 16px;
    font-weight: 400;
}

.sign-up-sec .privacy-terms {
    font-size: 18px;
    font-weight: normal;
    color: #000000;
    line-height: 22px;
    margin-bottom: 25px;
}
.sign-up-sec .privacy-terms a {
    font-size: 18px;    
    color: #000000;
    text-decoration: underline;    
}
.Toastify__toast{
    width: 450px;
    right: 20%;
}

/* Input style */

.sign-up-sec .form-group {
    position: relative;
    margin-bottom: 3%;
}
.sign-up-sec .custom-input {
    color: #727272;
    position: relative;    
    width: 100%;
    background: #fff;
    padding: 0 16px;
    height: 50px;
    border-radius: 5px;
    transition: all 0s ease;
    border: 1px solid #000000;
    font-size: 16px;
    box-shadow: none;    
}

.sign-up-sec .form-group .btn-text {
    color: #000000;
    font-size: 16px;
    font-weight: 700;
    text-decoration: underline;
}

.sign-up-sec textarea.custom-input {
    height: 100px;
    padding-top: 12px;
}
.sign-up-sec .custom-input:hover {
    border-color: #3381FF;
}
.sign-up-sec .custom-input.disabled {
    background-color: #dedede;
    cursor: not-allowed;
    pointer-events: none;
}
.sign-up-sec .custom-label {
    position: absolute;
    left: 16px;
    top: 12px;
    transition: all 0.3s ease;
    color: #626262;
    font-size: 16px;
    margin: 0;    
    pointer-events: none;    
}
.sign-up-sec .custom-input:focus {
    border-color: #003282;
    border-width: 2px;    
}


.sign-up-sec .custom-input:focus ~ label {
    color: #003282;
}
.sign-up-sec .custom-input:focus ~ label, .sign-up-sec .custom-input:valid ~ label {
    transform: translate3d(0px, -21px, 0px);    
    font-size: 12px;
    background: #fff;
    padding: 0 4px;
    left: 10px;
}


.sign-up-sec .custom-input.disabled:focus ~ label, .sign-up-sec .custom-input.disabled:valid ~ label {
    background-color: #dedede;
    color: #727272;
}

/* input[type="date"]::-webkit-calendar-picker-indicator {
    background: transparent;
    bottom: 0;
    color: transparent;
    cursor: pointer;
    height: auto;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: auto;
} */
.sign-up-sec .custom-input.disabled:focus {
    border-color: #727272 !important;
}
.sign-up-sec .has-danger .custom-input:focus ~ label, .sign-up-sec .has-danger .custom-input ~ label {
    color: #DB1E36;
}

.sign-up-sec .has-danger .custom-input {
    border-color: #DB1E36;
    outline: none;
}

.sign-up-sec .error-msg {
    padding: 3px 0 0 16px;
    color: #DB1E36;
    font-size: 70%;
}

.sign-up-sec .or {
    text-align: center;
    position: relative;    
}

.sign-up-sec .or span {
    font-size: 18px;
    line-height: 22px;
    font-weight: 400;
    width: 60px;
    display: block;
    margin: 0 auto;
    background-color: transparent;
    position: relative;
}

.sign-up-sec .or:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 20%;
    height: 2px;
    background-color: rgba(30,30,30,0.10);
    width: 88px;
    z-index: -1;
}

.sign-up-sec .or:after {
    content: "";
    position: absolute;
    top: 50%;
    right: 20%;
    height: 2px;
    background-color: rgba(30,30,30,0.10);
    width: 88px;
    z-index: -1;
}

.sign-up-sec .btn-yellow {
    text-transform: uppercase;
    padding: 4%;
    border-radius: 5px;
    background-color: #D79A2B;    
    font-size: 16px;
    font-weight: 500;
    color: #ffffff;
    line-height: 1;
}
.sign-up-sec .btn-yellow:hover {
    background-color: #ffffff;
    color: #D79A2B;
    border-color: #D79A2B;
}
.sign-up-sec .btn-yellow-brd {
    text-transform: uppercase;
    padding: 4%;
    border-radius: 5px;
    color: #D79A2B;    
    font-size: 16px;
    font-weight: 500;
    background-color: #ffffff;
    border-color: #D79A2B;
    line-height: 1;
}

.sign-up-sec .btn-yellow-brd:hover {
    color: #ffffff;
    background-color: #D79A2B;    
}

.sign-up-sec .eyeIcon{
    position: absolute;
    right: 0;
    margin-top: 1%;
    color: white;
}

/* Ṛesponsive */
@media only screen and (max-width: 767px) {
    .sign-up-sec.main-bg {
        height: auto;
    }
    .sign-up-sec .white-wrapper {
        padding: 22px;
    }
    .sign-up-sec .snacks-title {
        margin-bottom: 30px;
    }
}

@media only screen and (max-width: 480px) {
    .sign-up-sec .white-wrapper {
        max-width: 90%;
    }
}



/* ************************************************************
***************************************************************
--- Sign Up Page End ---
***************************************************************
************************************************************ */


/* ************************************************************
***************************************************************
--- Sign In Page Start ---
***************************************************************
************************************************************ */

.sign-in-sec.main-bg {
    /* background: url("../src/assests/images/RockstarBack.png") no-repeat 0 0 / cover; */
    background-size: cover;
    background-position: right;
    min-height: 100vh;
    width: 100%;
    padding: 20px;
    display: flex;
    align-items: center;
}
.sign-in-sec .white-wrapper {
    background-color: #fff;
    padding: 40px 50px 30px 50px;
    margin: 0 auto;
    max-width: 480px;    
    box-shadow: 0px 3px 10px #00000033;
    border-radius: 1%;    
    width: 100%;
}
.sign-in-sec .snacks-logo{
    margin-bottom: 10%;
}
.sign-in-sec .snacks-logo img {
    max-width: 40%;
    width: 100%;
    cursor: pointer;
}
.sign-in-sec .snacks-title {
    font-family: 'CocogoosePro-SemiLight';
    font-size: 18px;
    line-height: 24px;
    color: #1E1E1E;
    font-weight: normal;
    margin-bottom: 35px;
}

/* Input style */

.sign-in-sec .form-group {
    position: relative;
    /* margin-bottom: 25px; */
}
.sign-in-sec .custom-input {
    color: #727272;
    position: relative;    
    width: 100%;
    background: #fff;
    padding: 0 16px;
    height: 50px;
    border-radius: 5px;
    transition: all 0s ease;
    border: 1px solid #000000;
    font-size: 16px;
    box-shadow: none;    
}

.sign-in-sec .form-group .btn-text {
    color: #000000;
    font-size: 16px;
    font-weight: 700;
    text-decoration: underline;
}

.sign-in-sec textarea.custom-input {
    height: 100px;
    padding-top: 12px;
}
.sign-in-sec .custom-input:hover {
    border: 2px solid #3381FF;
}
.sign-in-sec .custom-input.disabled {
    background-color: #dedede;
    cursor: not-allowed;
    pointer-events: none;
}
.sign-in-sec .custom-label {
    position: absolute;
    left: 16px;
    top: 12px;
    transition: all 0.3s ease;
    color: #626262;
    font-size: 16px;
    margin: 0;    
    pointer-events: none;    
}
.sign-in-sec .custom-input:focus {
    border-color: #003282;
    border-width: 2px;    
}
.sign-in-sec .needhelpshow{
  display: block;
  overflow: hidden;
}

.sign-in-sec .needhelpclose{
    display: none;
    overflow: hidden;
}

.sign-in-sec .eyeIcon{
    position: absolute;
    right: 0;
    margin-top: 1%;
    color: rgb(240, 238, 238);
}


.sign-in-sec .custom-input:focus ~ label {
    color: #003282;
}
.sign-in-sec .custom-input:focus ~ label, .sign-in-sec .custom-input:valid ~ label {
    transform: translate3d(0px, -21px, 0px);    
    font-size: 12px;
    background: #fff;
    padding: 0 4px;
    left: 10px;
}
.sign-in-sec .custom-input.disabled:focus ~ label, .sign-in-sec .custom-input.disabled:valid ~ label {
    background-color: #dedede;
    color: #727272;
}
.sign-in-sec .custom-input.disabled:focus {
    border-color: #727272 !important;
}
.sign-in-sec .has-danger .custom-input:focus ~ label, .sign-in-sec .has-danger .custom-input ~ label {
    color: #DB1E36;
}

.sign-in-sec .has-danger .custom-input {
    border-color: #DB1E36;
    outline: none;
}



.sign-in-sec .error-msg {
    padding: 3px 0 0 16px;
    color: #DB1E36;
    font-size: 12px;
}

.sign-in-sec .or {
    text-align: center;
    position: relative;    
}

.sign-in-sec .or span {
    font-size: 18px;
    line-height: 22px;
    font-weight: 400;
    width: 60px;
    display: block;
    margin: 0 auto;
    background-color: transparent;
    position: relative;
}

.sign-in-sec .or:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 20%;
    height: 2px;
    background-color: rgba(30,30,30,0.10);
    width: 88px;
    z-index: -1;
}

.sign-in-sec .or:after {
    content: "";
    position: absolute;
    top: 50%;
    right: 20%;
    height: 2px;
    background-color: rgba(30,30,30,0.10);
    width: 88px;
    z-index: -1;
}

.sign-in-sec .btn-yellow {
    text-transform: uppercase;
    padding: 15px;
    border-radius: 5px;
    background-color: #D79A2B;    
    font-size: 16px;
    font-weight: 500;
    color: #ffffff;
    line-height: 1;
}
.sign-in-sec .btn-yellow:hover {
    background-color: #ffffff;
    color: #D79A2B;
    border-color: #D79A2B;
}

/* Ṛesponsive */
@media only screen and (max-width: 767px) {
    .sign-in-sec .white-wrapper {
        padding: 22px;
    }
    
    /* .sign-in-sec .snacks-title {
        margin-bottom: 30px;
    } */
}

@media only screen and (max-width: 480px) {
    .sign-in-sec .white-wrapper {
        max-width: 90%;
    }
    
}


/* ************************************************************
***************************************************************
--- Sign In Page End ---
***************************************************************
************************************************************ */


/* ************************************************************
***************************************************************
--- Forgot Password Page Start ---
***************************************************************
************************************************************ */


.forgot-password-sec.main-bg {
    /* background: url("../src/assests/images/RockstarBack.png") no-repeat 0 0 / cover; */
    background-size: cover;
    min-height: 100vh;
    width: 100%;
    padding: 20px;
    /* height: 100vh; */
    display: flex;
    align-items: center;
}
.forgot-password-sec .white-wrapper {
    background-color: #fff;
    padding: 35px 35px 50px 30px;
    margin: 0 auto;
    max-width: 480px;    
    box-shadow: 0px 3px 10px #00000033;
    border-radius: 1%;    
    width: 100%;
}

.forgot-password-sec .snacks-logo {
    margin-bottom: 10%;
}
.forgot-password-sec .snacks-logo img {
    max-width: 40%;
    width: 100%;
    cursor: pointer;
}
.forgot-password-sec .snacks-title {
    font-family: 'CocogoosePro-SemiLight';
    font-size: 18px;
    line-height: 24px;
    color: #1E1E1E;
    font-weight: normal;
    margin-bottom: 7%;
}

/* Input style */

.forgot-password-sec .form-group {
    position: relative;
    margin-bottom: 4%;
}
.forgot-password-sec .custom-input {
    color: #727272;
    position: relative;
    outline: 0;
    width: 100%;
    background: #fff;
    padding: 0 16px;
    height: 55px;
    border-radius: 5px;
    transition: all 0s ease;
    border: 1px solid #000000;
    font-size: 16px;    
}

.forgot-password-sec .form-group .btn-text {
    color: #000000;
    font-size: 16px;
    font-weight: 700;
    text-decoration: underline;
}

.forgot-password-sec .form-group .back-sign-in {
    color: #1E1E1E;
    font-size: 16px;
    font-weight: 400;
    padding-right: 1%;
}

.forgot-password-sec textarea.custom-input {
    height: 100px;
    padding-top: 12px;
}
.forgot-password-sec .custom-input:hover {
    border-color: #3381FF;
}
.forgot-password-sec .custom-input.disabled {
    background-color: #dedede;
    cursor: not-allowed;
    pointer-events: none;
}
.forgot-password-sec .custom-label {
    position: absolute;
    left: 16px;
    top: 17px;
    transition: all 0.3s ease;
    color: #626262;
    font-size: 16px;
    margin: 0;    
    pointer-events: none;    
}
.forgot-password-sec .custom-input:focus {
    border-color: #003282;
    border-width: 2px;    
}

.forgot-password-sec .custom-input:focus ~ label {
    color: #003282;
}
.forgot-password-sec .custom-input:focus ~ label, .forgot-password-sec .custom-input:valid ~ label {
    transform: translate3d(0px, -21px, 0px);    
    font-size: 12px;
    background: #fff;
    padding: 0 4px;
    left: 10px;
}
.forgot-password-sec .custom-input.disabled:focus ~ label, .forgot-password-sec .custom-input.disabled:valid ~ label {
    background-color: #dedede;
    color: #727272;
}
.forgot-password-sec .custom-input.disabled:focus {
    border-color: #727272 !important;
}
.forgot-password-sec .has-danger .custom-input:focus ~ label, .forgot-password-sec .has-danger .custom-input:valid ~ label {
    color: #DB1E36;
}
.forgot-password-sec .has-danger .custom-input {
    border-color: #DB1E36;
}
.forgot-password-sec .error-msg {
    padding: 3px 0 0 16px;
    color: #DB1E36;
    font-size: 12px;
}

.forgot-password-sec .or {
    text-align: center;
    position: relative;    
}

.forgot-password-sec .or span {
    font-size: 18px;
    line-height: 22px;
    font-weight: 400;
    width: 60px;
    display: block;
    margin: 0 auto;
    background-color: transparent;
    position: relative;
}

.forgot-password-sec .or:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 20%;
    height: 2px;
    background-color: rgba(30,30,30,0.10);
    width: 88px;
    z-index: -1;
}

.forgot-password-sec .or:after {
    content: "";
    position: absolute;
    top: 50%;
    right: 20%;
    height: 2px;
    background-color: rgba(30,30,30,0.10);
    width: 88px;
    z-index: -1;
}

.forgot-password-sec .btn-yellow {
    text-transform: uppercase;
    padding: 4%;
    border-radius: 5px;
    background-color: #D79A2B;    
    font-size: 16px;
    font-weight: 500;
    color: #ffffff;
    line-height: 1;
}
.forgot-password-sec .btn-yellow:hover {
    background-color: #ffffff;
    color: #D79A2B;
    border-color: #D79A2B;
}
.forgot-password-sec .notification-bar {
    position: fixed;
    top: 0;
    left: 0;
    background-color: #ffe600;
    width: 100%;
    padding: 13px;
    font-size: 18px;
    font-weight: normal;
    line-height: 24px;
}
.forgot-password-sec .notification-bar p {
    text-align: center;
    margin-bottom: 0;
    font-size: 18px;
    line-height: 24px;
    font-weight: 300;
}
.forgot-password-sec .notification-bar img {
    width: 22px;
    height: 22px;
    margin-right: 10px;
}

/* Ṛesponsive */
@media only screen and (max-width: 767px) {
    .forgot-password-sec .white-wrapper {
        padding: 22px;
    }
    .forgot-password-sec .snacks-title {
        margin-bottom: 30px;
    }
}

@media only screen and (max-width: 480px) {
    .forgot-password-sec .white-wrapper {
        max-width: 90%;
    }
}

/* ************************************************************
***************************************************************
--- Forgot Password Page End ---
***************************************************************
************************************************************ */

/* ************************************************************
***************************************************************
--- Change Password Page End ---
***************************************************************
************************************************************ */

.change-password-sec.main-bg {
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 100vh;
    width: 100%;
    padding: 80px 20px;
    /* height: auto; */
    display: flex;
    align-items: center;
}
.change-password-sec .white-wrapper {
    background-color: #fff;
    padding: 35px 35px 50px 30px;
    margin: 0 auto;
    max-width: 480px;    
    box-shadow: 0px 3px 10px #00000033;
    border-radius: 1%;    
    width: 100%;
}

.change-password-sec .snacks-logo {
    margin-bottom: 10%;
}
.change-password-sec .snacks-logo img {
    max-width: 40%;
    width: 100%;
}
.change-password-sec .snacks-title {
    font-family: 'CocogoosePro-SemiLight';
    font-size: 18px;
    line-height: 24px;
    color: #1E1E1E;
    font-weight: normal;
    margin-bottom: 9%;
}

.change-password-sec .sub-title {
    font-size: 18px;
    color: #1E1E1E;
    font-weight: 400;
    line-height: 22px;
    margin-bottom: 30px;
}

.change-password-sec .before-sign-in {
    font-size: 16px;
    color: #1E1E1E;
    font-weight: 400;
    line-height: 22px;
    margin-bottom: 25px;
}

.change-password-sec .before-sign-in a{    
    font-weight: 700;
    color: #1E1E1E;
    text-decoration: underline;
    padding-left: 10px;
}

.change-password-sec .pwd-requirement {
    margin-top: 10px;
    padding-bottom: 23px;
    border-bottom: 1px solid #707070;
    margin-bottom: 5%;
}

.change-password-sec .pwd-requirement p {
    margin-bottom: 5px;
}

.change-password-sec .pwd-requirement li {
    color: #1E1E1E;
    font-size: 16px;
    font-weight: 400;
}

.change-password-sec .privacy-terms {
    font-size: 18px;
    font-weight: normal;
    color: #000000;
    line-height: 22px;
    margin-bottom: 5%;
}
.change-password-sec .privacy-terms a {
    font-size: 18px;    
    color: #000000;
    text-decoration: underline;    
}

.change-password-sec .brd-btm {
    border-bottom: 1px solid #707070;
    display: inline-block;
    width: 100%;
}

.change-password-sec .line-height-0 {
    line-height: 0;
}

.change-password-sec .font-size-0 {
    font-size: 0;
}

/* Input style */

.change-password-sec .form-group {
    position: relative;
    margin-bottom: 4%;
}
.change-password-sec .custom-input {
    color: #727272;
    position: relative;    
    width: 100%;
    background: #fff;
    padding: 0 16px;
    height: 50px;
    border-radius: 5px;
    transition: all 0s ease;
    border: 1px solid #000000;
    font-size: 16px;
    box-shadow: none;    
}

.change-password-sec .form-group .btn-text {
    color: #000000;
    font-size: 16px;
    font-weight: 700;
    text-decoration: underline;
}

.change-password-sec textarea.custom-input {
    height: 100px;
    padding-top: 12px;
}
.change-password-sec .custom-input:hover {
    border-color: #3381FF;
}
.change-password-sec .custom-input.disabled {
    background-color: #dedede;
    cursor: not-allowed;
    pointer-events: none;
}
.change-password-sec .custom-label {
    position: absolute;
    left: 16px;
    top: 12px;
    transition: all 0.3s ease;
    color: #626262;
    font-size: 16px;
    margin: 0;    
    pointer-events: none;    
}
.change-password-sec .custom-input:focus {
    border-color: #003282;
    border-width: 2px;    
}
.change-password-sec .eyeIcon{
    position: absolute;
    right: 0;
    margin-top: 1%;
    color: rgb(244, 240, 240);
}

.change-password-sec .custom-input:focus ~ label {
    color: #003282;
}
.change-password-sec .custom-input:focus ~ label, .change-password-sec .custom-input:valid ~ label {
    transform: translate3d(0px, -21px, 0px);    
    font-size: 12px;
    background: #fff;
    padding: 0 4px;
    left: 10px;
}
.change-password-sec .custom-input.disabled:focus ~ label, .change-password-sec .custom-input.disabled:valid ~ label {
    background-color: #dedede;
    color: #727272;
}
.change-password-sec .custom-input.disabled:focus {
    border-color: #727272 !important;
}
.change-password-sec .has-danger .custom-input:focus ~ label, .change-password-sec .has-danger .custom-input ~ label {
    color: #DB1E36;
}
.change-password-sec .has-danger .custom-input {
    border-color: #DB1E36;
    outline: none;
}
.change-password-sec .error-msg {
    padding: 3px 0 0 16px;
    color: #DB1E36;
    font-size: 12px;
}

.change-password-sec .or {
    text-align: center;
    position: relative;    
}

.change-password-sec .or span {
    font-size: 18px;
    line-height: 22px;
    font-weight: 400;
    width: 60px;
    display: block;
    margin: 0 auto;
    background-color: transparent;
    position: relative;
}

.change-password-sec .or:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 20%;
    height: 2px;
    background-color: rgba(30,30,30,0.10);
    width: 88px;
    z-index: -1;
}

.change-password-sec .or:after {
    content: "";
    position: absolute;
    top: 50%;
    right: 20%;
    height: 2px;
    background-color: rgba(30,30,30,0.10);
    width: 88px;
    z-index: -1;
}

.change-password-sec .btn-yellow {
    text-transform: uppercase;
    padding: 4%;
    border-radius: 5px;
    background-color: #D79A2B;    
    font-size: 100%;
    font-weight: 500;
    color: #ffffff;
    line-height: 1;
}
.change-password-sec .btn-yellow:hover {
    background-color: #ffffff;
    color: #D79A2B;
    border-color: #D79A2B;
}
.change-password-sec .btn-yellow-brd {
    text-transform: uppercase;
    padding: 4%;
    border-radius: 5px;
    color: #D79A2B;    
    font-size: 100%;
    font-weight: 500;
    background-color: #ffffff;
    border-color: #D79A2B;
    line-height: 1;
}

.change-password-sec .btn-yellow-brd:hover {
    color: #ffffff;
    background-color: #D79A2B;    
}

/* Ṛesponsive */
@media only screen and (max-width: 767px) {
    .change-password-sec.main-bg {
        height: auto;
    }
    .change-password-sec .white-wrapper {
        padding: 22px;
    }
    .change-password-sec .snacks-title {
        margin-bottom: 10%;
    }
}

@media only screen and (max-width: 480px) {
    .change-password-sec .white-wrapper {
        max-width: 90%;
    }
}

/* ************************************************************
***************************************************************
--- Change Password Page End ---
***************************************************************
************************************************************ */

/* ************************************************************
***************************************************************
--- Reset Password Page Start ---
***************************************************************
************************************************************ */


.reset-password-sec.main-bg {
    /* background: url("../src/assests/images/RockstarBack.png") no-repeat 0 0 / cover; */
    background-size: cover;
    min-height: 100vh;
    width: 100%;
    padding: 20px;
    /* height: 100vh; */
    display: flex;
    align-items: center;
}

.reset-password-sec .white-wrapper {
    background-color: #fff;
    padding: 35px 35px 50px 30px;
    margin: 0 auto;
    max-width: 480px;    
    box-shadow: 0px 3px 10px #00000033;
    border-radius: 1%;    
    width: 100%;
}

.reset-password-sec .snacks-logo {
    margin-bottom: 10%;
}
.reset-password-sec .snacks-logo img {
    max-width: 40%;
    width: 100%;
    cursor: pointer;
}
.reset-password-sec .snacks-title {
    font-family: 'CocogoosePro-SemiLight';
    font-size: 18px;
    line-height: 24px;
    color: #1E1E1E;
    font-weight: normal;
    margin-bottom: 10%;
}

/* Input style */

.reset-password-sec .form-group {
    position: relative;
    margin-bottom: 4%;
}
.reset-password-sec .custom-input {
    color: #727272;
    position: relative;
    outline: 0;
    width: 100%;
    background: #fff;
    padding: 0 16px;
    height: 50px;
    border-radius: 5px;
    transition: all 0s ease;
    border: 1px solid #000000;
    font-size: 16px;    
}

.reset-password-sec .form-group .btn-text {
    color: #000000;
    font-size: 16px;
    font-weight: 700;
    text-decoration: underline;
}

.reset-password-sec .form-group .back-sign-in {
    color: #1E1E1E;
    font-size: 16px;
    font-weight: 400;
    padding-right: 1%;
}

.reset-password-sec textarea.custom-input {
    height: 100px;
    padding-top: 12px;
}
.reset-password-sec .custom-input:hover {
    border-color: #3381FF;
}
.reset-password-sec .custom-input.disabled {
    background-color: #dedede;
    cursor: not-allowed;
    pointer-events: none;
}
.reset-password-sec .custom-label {
    position: absolute;
    left: 16px;
    top: 12px;
    transition: all 0.3s ease;
    color: #626262;
    font-size: 16px;
    margin: 0;    
    pointer-events: none;    
}
.reset-password-sec .custom-input:focus {
    border-color: #003282;
    border-width: 2px;    
}

.reset-password-sec .custom-input:focus ~ label {
    color: #003282;
}
.reset-password-sec .custom-input:focus ~ label, .reset-password-sec .custom-input:valid ~ label {
    transform: translate3d(0px, -21px, 0px);    
    font-size: 12px;
    background: #fff;
    padding: 0 4px;
    left: 10px;
}
.reset-password-sec .custom-input.disabled:focus ~ label, .reset-password-sec .custom-input.disabled:valid ~ label {
    background-color: #dedede;
    color: #727272;
}
.reset-password-sec .custom-input.disabled:focus {
    border-color: #727272 !important;
}
.reset-password-sec .has-danger .custom-input:focus ~ label, .reset-password-sec .has-danger .custom-input ~ label {
    color: #DB1E36;
}
.reset-password-sec .has-danger .custom-input {
    border-color: #DB1E36;
}
.reset-password-sec .error-msg {
    padding: 3px 0 0 16px;
    color: #DB1E36;
    font-size: 12px;
}

.reset-password-sec .or {
    text-align: center;
    position: relative;    
}

.reset-password-sec .or span {
    font-size: 18px;
    line-height: 22px;
    font-weight: 400;
    width: 60px;
    display: block;
    margin: 0 auto;
    background-color: transparent;
    position: relative;
}

.reset-password-sec .or:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 20%;
    height: 2px;
    background-color: rgba(30,30,30,0.10);
    width: 88px;
    z-index: -1;
}
.reset-password-sec .eyeIcon{
    position: absolute;
    right: 0;
    margin-top: 1%;
    color: rgb(244, 240, 240);
}

.reset-password-sec .or:after {
    content: "";
    position: absolute;
    top: 50%;
    right: 20%;
    height: 2px;
    background-color: rgba(30,30,30,0.10);
    width: 88px;
    z-index: -1;
}

.reset-password-sec .btn-yellow {
    text-transform: uppercase;
    padding: 5%;
    border-radius: 5px;
    background-color: #D79A2B;    
    font-size: 16px;
    font-weight: 500;
    color: #ffffff;
    line-height: 1;
}
.reset-password-sec .btn-yellow:hover {
    background-color: #ffffff;
    color: #D79A2B;
    border-color: #D79A2B;
}
.reset-password-sec .notification-bar {
    position: fixed;
    top: 0;
    left: 0;
    background-color: #ffe600;
    width: 100%;
    padding: 13px;
    font-size: 18px;
    font-weight: normal;
    line-height: 24px;
}
.reset-password-sec .notification-bar p {
    text-align: center;
    margin-bottom: 0;
    font-size: 18px;
    line-height: 24px;
    font-weight: 300;
}
.reset-password-sec .notification-bar img {
    width: 22px;
    height: 22px;
    margin-right: 10px;
}

/* Ṛesponsive */
@media only screen and (max-width: 767px) {
    .reset-password-sec .white-wrapper {
        padding: 22px;
    }
    .reset-password-sec .snacks-title {
        margin-bottom: 10%;
    }
}

@media only screen and (max-width: 480px) {
    .reset-password-sec .white-wrapper {
        max-width: 90%;
    }
}

/* ************************************************************
***************************************************************
--- Reset Password Page End ---
***************************************************************
************************************************************ */



/* ************************************************************
***************************************************************
--- Sign Up Page Start ---
***************************************************************
************************************************************ */

.update-sec.main-bg {
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 100vh;
    width: 100%;
    padding: 80px 20px;
    display: flex;
    align-items: center;
}
.update-sec .white-wrapper {
    background-color: #fff;
    padding: 35px 35px 50px 30px;
    margin: 0 auto;
    max-width: 480px;    
    box-shadow: 0px 3px 10px #00000033;
    border-radius: 1%;    
    width: 100%;
}

.update-sec .snacks-logo {
    margin-bottom: 10%;
}
.update-sec .snacks-logo img {
    max-width: 40%;
    width: 100%;
}
.update-sec .snacks-title {
    font-family: 'CocogoosePro-SemiLight';
    font-size: 18px;
    line-height: 24px;
    color: #1E1E1E;
    font-weight: normal;
    margin-bottom: 10%;
}

.update-sec .sub-title {
    font-size: 18px;
    color: #1E1E1E;
    font-weight: 400;
    line-height: 22px;
    margin-bottom: 30px;
}

.update-sec .before-sign-in {
    font-size: 16px;
    color: #1E1E1E;
    font-weight: 400;
    line-height: 22px;
    margin-bottom: 25px;
}

.update-sec .before-sign-in a{    
    font-weight: 700;
    color: #1E1E1E;
    text-decoration: underline;
    padding-left: 10px;
}

.update-sec .pwd-requirement {
    margin-top: 10px;
    padding-bottom: 23px;
    border-bottom: 1px solid #707070;
    margin-bottom: 20px;
}

.update-sec .pwd-requirement p {
    margin-bottom: 5px;
}

.update-sec .pwd-requirement li {
    color: #1E1E1E;
    font-size: 16px;
    font-weight: 400;
}

.update-sec.privacy-terms {
    font-size: 18px;
    font-weight: normal;
    color: #000000;
    line-height: 22px;
    margin-bottom: 25px;
}
.update-sec .privacy-terms a {
    font-size: 18px;    
    color: #000000;
    text-decoration: underline;    
}

/* Input style */

.update-sec .form-group {
    position: relative;
    margin-bottom: 5%;
}
.update-sec .custom-input {
    color: #727272;
    position: relative;    
    width: 100%;
    background: #fff;
    padding: 0 16px;
    height: 50px;
    border-radius: 5px;
    transition: all 0s ease;
    border: 1px solid #000000;
    font-size: 16px;
    box-shadow: none;    
}

.update-sec .form-group .btn-text {
    color: #000000;
    font-size: 16px;
    font-weight: 700;
    text-decoration: underline;
}

.update-sec textarea.custom-input {
    height: 100px;
    padding-top: 12px;
}
.update-sec .custom-input:hover {
    border-color: #3381FF;
}
.update-sec .custom-input.disabled {
    background-color: #dedede;
    cursor: not-allowed;
    pointer-events: none;
}
.update-sec .custom-label {
    position: absolute;
    left: 16px;
    top: 12px;
    transition: all 0.3s ease;
    color: #626262;
    font-size: 16px;
    margin: 0;    
    pointer-events: none;    
}
.update-sec .custom-input:focus {
    border-color: #003282;
    border-width: 2px;    
}


.update-sec .custom-input:focus ~ label {
    color: #003282;
}
.update-sec .custom-input:focus ~ label, .update-sec .custom-input:valid ~ label {
    transform: translate3d(0px, -21px, 0px);    
    font-size: 12px;
    background: #fff;
    padding: 0 4px;
    left: 10px;
}
.update-sec .custom-input:disabled ~ label {
    transform: translate3d(0px, -21px, 0px);    
    font-size: 12px;
    background: #ffff;
    padding: 0 4px;
    color: #1e1e1e;
    border-radius: 3px;
    left: 10px;
}
.update-sec.custom-input.disabled:focus ~ label, .update-sec.custom-input.disabled:valid ~ label {
    background-color: #dedede;
    color: #727272;
}
.update-sec .custom-input.disabled:focus {
    border-color: #727272 !important;
}
.update-sec .has-danger .custom-input:focus ~ label, .update-sec .has-danger .custom-input ~ label {
    color: #DB1E36;
}

.update-sec .has-danger .custom-input {
    border-color: #DB1E36;
    outline: none;
}

.update-sec .error-msg {
    padding: 3px 0 0 16px;
    color: #DB1E36;
    font-size: 12px;
}

.update-sec .or {
    text-align: center;
    position: relative;    
}

.update-sec .or span {
    font-size: 18px;
    line-height: 22px;
    font-weight: 400;
    width: 60px;
    display: block;
    margin: 0 auto;
    background-color: transparent;
    position: relative;
}

.update-sec .or:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 20%;
    height: 2px;
    background-color: rgba(30,30,30,0.10);
    width: 88px;
    z-index: -1;
}

.update-sec .or:after {
    content: "";
    position: absolute;
    top: 50%;
    right: 20%;
    height: 2px;
    background-color: rgba(30,30,30,0.10);
    width: 88px;
    z-index: -1;
}

.update-sec .btn-yellow {
    text-transform: uppercase;
    padding: 15px;
    border-radius: 5px;
    background-color: #D79A2B;    
    font-size: 16px;
    font-weight: 500;
    color: #ffffff;
    line-height: 1;
}
.update-sec .btn-yellow:hover {
    background-color: #ffffff;
    color: #D79A2B;
    border-color: #D79A2B;
}
.update-sec .btn-yellow-brd {
    text-transform: uppercase;
    padding: 15px;
    border-radius: 5px;
    color: #D79A2B;    
    font-size: 16px;
    font-weight: 500;
    background-color: #ffffff;
    border-color: #D79A2B;
    line-height: 1;
}

.update-sec .btn-yellow-brd:hover {
    color: #ffffff;
    background-color: #D79A2B;    
}

/* Ṛesponsive */
@media only screen and (max-width: 767px) {
    /* .update-sec.main-bg {
        height: auto;
    } */
    .update-sec .white-wrapper {
        padding: 22px;
    }
    .update-sec .snacks-title {
        margin-bottom: 10%;
    }
}

@media only screen and (max-width: 480px) {
    .update-sec .white-wrapper {
        max-width: 90%;
    }
}



/* ************************************************************
***************************************************************
--- Sign Up Page End ---
***************************************************************
************************************************************ */

/* ************************************************************
***************************************************************
--- Configure Page Start ---
***************************************************************
************************************************************ */

.configure-sec.main-bg {
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 100vh;
    width: 100%;
    padding: 80px 20px;
    display: flex;
    align-items: center;
}
.configure-sec .white-wrapper {
    background-color: #fff;
    padding: 35px 35px 50px 30px;
    margin: 0 auto;
    max-width: 480px;    
    box-shadow: 0px 3px 10px #00000033;
    border-radius: 1%;    
    width: 100%;
}

.configure-sec .snacks-logo {
    margin-bottom: 10%;
}
.configure-sec .snacks-logo img {
    max-width: 40%;
    width: 100%;
    cursor: pointer;
}
.configure-sec .snacks-title {
    font-family: 'CocogoosePro-SemiLight';
    font-size: 18px;
    line-height: 24px;
    color: #1E1E1E;
    font-weight: normal;
    margin-bottom: 4%;
}

.configure-sec .sub-title {
    font-size: 18px;
    color: #1E1E1E;
    font-weight: 400;
    line-height: 22px;
    margin-bottom: 5%;
}

.configure-sec .before-sign-in {
    font-size: 16px;
    color: #1E1E1E;
    font-weight: 400;
    line-height: 22px;
    margin-bottom: 25px;
}

.configure-sec .before-sign-in a{    
    font-weight: 700;
    color: #1E1E1E;
    text-decoration: underline;
    padding-left: 10px;
}

.configure-sec .pwd-requirement {
    margin-top: 10px;
    padding-bottom: 23px;
    border-bottom: 1px solid #707070;
    margin-bottom: 20px;
}

.configure-sec .pwd-requirement p {
    margin-bottom: 5px;
}

.configure-sec .pwd-requirement li {
    color: #1E1E1E;
    font-size: 16px;
    font-weight: 400;
}

.configure-sec .privacy-terms {
    font-size: 18px;
    font-weight: normal;
    color: #000000;
    line-height: 22px;
    margin-bottom: 25px;
}
.configure-sec .privacy-terms a {
    font-size: 18px;    
    color: #000000;
    text-decoration: underline;    
}

/* Input style */

.configure-sec .form-group {
    position: relative;
    margin-bottom: 4%;
}
.configure-sec .custom-input {
    color: #727272;
    position: relative;    
    width: 100%;
    background: #fff;
    padding: 0 16px;
    height: 50px;
    border-radius: 5px;
    transition: all 0s ease;
    border: 1px solid #000000;
    font-size: 16px;
    box-shadow: none;    
}

.configure-sec .custom-select-dropdown select{
    color: #727272;
    position: relative;    
    width: 100%;
    background: #fff;
    padding: 0 16px;
    height: 50px !important;
    border-radius: 5px;
    transition: all 0s ease;
    border: 1px solid #000000;
    font-size: 16px;
    box-shadow: none;   
}

.configure-sec .form-group .btn-text {
    color: #000000;
    font-size: 16px;
    font-weight: 700;
    text-decoration: underline;
}

.configure-sec .form-group .label-text{
    margin-top: 1%;
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 400;
    color: #000000;
}

.configure-sec .qr-code-scan {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 45px 0;
}

.configure-sec .qr-code-img {
    width: 80px;
    height: 80px;
    overflow: hidden;
}

.configure-sec .qr-code-img img{
    width: 100%;
    object-fit: cover;
}

.configure-sec textarea.custom-input {
    height: 100px;
    padding-top: 12px;
}
.configure-sec .custom-input:hover {
    border-color: #3381FF;
}
.configure-sec .custom-input.disabled {
    background-color: #dedede;
    cursor: not-allowed;
    pointer-events: none;
}
.configure-sec .custom-label {
    position: absolute;
    left: 16px;
    top: 12px;
    transition: all 0.3s ease;
    color: #626262;
    font-size: 16px;
    margin: 0;    
    pointer-events: none;    
}
.configure-sec .custom-input:disabled ~ label{
    transform: translate3d(0px, -21px, 0px);    
    font-size: 12px;
    background: #fff;
    padding: 0 4px;
    left: 10px;
}

.configure-sec .custom-input:focus {
    border-color: #003282;
    border-width: 2px;    
}


.configure-sec .custom-input:focus ~ label {
    color: #003282;
}
.configure-sec .custom-input:focus ~ label, .configure-sec .custom-input:valid ~ label {
    transform: translate3d(0px, -21px, 0px);    
    font-size: 12px;
    background: #fff;
    padding: 0 4px;
    left: 10px;
}
.configure-sec .custom-input.disabled:focus ~ label, .configure-sec .custom-input.disabled:valid ~ label {
    background-color: #dedede;
    color: #727272;
}
.configure-sec .custom-input.disabled:focus {
    border-color: #727272 !important;
}
.configure-sec .has-danger .custom-input:focus ~ label, .configure-sec .has-danger .custom-input ~ label {
    color: #DB1E36;
}
.configure-sec .has-danger .custom-input {
    border-color: #DB1E36;
    outline: none;
}
.configure-sec .error-msg {
    padding: 3px 0 0 16px;
    color: #DB1E36;
    font-size: 12px;
}

.configure-sec .or {
    text-align: center;
    position: relative;    
}

.configure-sec .or span {
    font-size: 18px;
    line-height: 22px;
    font-weight: 400;
    width: 60px;
    display: block;
    margin: 0 auto;
    background-color: transparent;
    position: relative;
}

.configure-sec .or:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 20%;
    height: 2px;
    background-color: rgba(30,30,30,0.10);
    width: 88px;
    z-index: -1;
}

.configure-sec .or:after {
    content: "";
    position: absolute;
    top: 50%;
    right: 20%;
    height: 2px;
    background-color: rgba(30,30,30,0.10);
    width: 88px;
    z-index: -1;
}

.configure-sec .btn-yellow {
    text-transform: uppercase;
    padding: 4%;
    border-radius: 5px;
    background-color: #D79A2B;    
    font-size: 100%;
    font-weight: 500;
    color: #ffffff;
    line-height: 1;
}
.configure-sec .btn-yellow:hover {
    background-color: #ffffff;
    color: #D79A2B;
    border-color: #D79A2B;
}
.configure-sec .btn-yellow-brd {
    text-transform: uppercase;
    padding: 4%;
    border-radius: 5px;
    color: #D79A2B;    
    font-size: 16px;
    font-weight: 500;
    background-color: #ffffff;
    border-color: #D79A2B;
    line-height: 1;
}

.configure-sec .btn-yellow-brd:hover {
    color: #ffffff;
    background-color: #D79A2B;    
}

/* Radio button */
.configure-sec .radio-custom {
    opacity: 0;
    position: absolute;   
}

.configure-sec .radio-custom, .configure-sec .radio-custom-label {
    display: inline-block;
    vertical-align: middle;    
    cursor: pointer;
    margin-bottom: 0;
    font-size: 16px;
    font-weight: 700;
    color: #000000;
}

.configure-sec .radio-custom + .radio-custom-label.radio-custom-label-green:before {
    border-color: #008400;
}

.configure-sec .radio-custom:checked + .radio-custom-label.radio-custom-label-green::after {
    filter: invert(21%) sepia(86%) saturate(3834%) hue-rotate(106deg) brightness(37%) contrast(94%);
}

.configure-sec .radio-custom:checked + .radio-custom-label.radio-custom-label-green {
    color: #008400;
}

.configure-sec .radio-custom:checked + .radio-custom-label.radio-custom-label-green:before {
    background-color: transparent;
    border-color:#008400 ;
}

.configure-sec .radio-custom-label {
    position: relative;
}

.configure-sec .radio-custom + .radio-custom-label:before {
    content: '';
    background: #fff;
    border: 2px solid #707070;
    display: inline-block;
    vertical-align: middle;
    width: 1.5rem;
    height: 1.5rem;
    padding: 2px;
    margin-right: 10px;
    text-align: center;
    border-radius: 50%;
}

.configure-sec .radio-custom:checked + .radio-custom-label:before {
    border-radius: 50%;
    background-color: #0076B3;
    border-color: #0076B3;
}

.configure-sec .radio-custom:checked + .radio-custom-label::after {
    position: absolute;
    left: 3px;
    top: 3px;
    content: "";
    background-image: url(../src/assests/images/checkmark-success.png) ;
    width: 20px;
    height: 20px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 70%;
}

/* Custom Checkbox */
/* The container */
.custom-checkbox {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 16px;
    line-height: 22px;
    font-weight: 400;
    color: #000000;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  
  /* Hide the browser's default checkbox */
  .custom-checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;    
  }
  
  /* Create a custom checkbox */
  .custom-checkbox .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 24px;
    width: 24px;
    background-color: transparent;
    border: 2px solid #707070;
    border-radius: 4px;
  }
  
  /* On mouse-over, add a grey background color */
  .custom-checkbox:hover input ~ .checkmark {
    background-color: #ccc;
  }
  
  /* When the checkbox is checked, add a blue background */
  .custom-checkbox input:checked ~ .checkmark {
    background-color: #0076B3;
    border-color: #0076B3;
  }
  
  /* Create the checkmark/indicator (hidden when not checked) */
  .custom-checkbox .checkmark:after {
    position: absolute;
    left: 0px;
    top: -1px;
    content: "";
    background-image: url(../src/assests/images/icons-white-checkmark.png) ;
    width: 20px;
    height: 20px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 70%;
    display: none;
  }
  
  /* Show the checkmark when checked */
  .custom-checkbox input:checked ~ .checkmark:after {
    display: block;
  }  
  

.text-decoration-none {
    text-decoration: none !important;
}

.configure-sec .radio-custom:focus + .radio-custom-label {
    outline: none; /* focus style */
}
.configure-sec .form-text {
    font-size: 80%;
    line-height: 18px;
    font-weight: 400;
    color: #000000;
    margin-bottom: 5%;
}


/* Ṛesponsive */
@media only screen and (max-width: 767px) {
    
    .configure-sec .white-wrapper {
        padding: 22px;
    }
    .configure-sec .snacks-title {
        margin-bottom: 30px;
    }
}

@media only screen and (max-width: 480px) {
    .configure-sec .white-wrapper {
        max-width: 90%;
    }
}

/* ************************************************************
***************************************************************
--- Configure Page End ---
***************************************************************
************************************************************ */

/* ************************************************************
***************************************************************
--- Not Found Page Start ---
***************************************************************
************************************************************ */


.not-found-sec.main-bg {
    background-color: #E5ECF8;
    height: 100vh;
    width: 100%;   
}
.not-found-sec-logo{
    display: flex;
    justify-content: center;
}
.not-found-sec-logo img{
    position: relative;
    top: 47px;
    height: 100px;
    width: 204px;
}
.not-found-sec-content{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
    top: 39%;
    left: 50%;
    transform: translate(-50%,-50%);
    
}

.not-found-sec-content .content404{
    font-weight: 900;
    color: #003282;
    font-size: 150px;   
}
.not-found-sec-content .content-oops{
    color: #1E1E1E;
    font-size: 35px;
    font-family: 'CocogoosePro-SemiLight';
}

/* Ṛesponsive */
@media only screen and (max-width: 767px) {
    
    .not-found-sec-logo img {
        height: 80px;
        width: 100px;
    }
    .not-found-sec-content .content404{  
        font-size: 90px;
    }
    .not-found-sec-content .content-oops{
        font-size: 25px;
    }
    
}

@media only screen and (max-width: 480px) {
    .not-found-sec-logo img {
        height: 50px;
        width: 70px;
    }
    .not-found-sec-content .content404{
        font-size: 50px;
    }
    .not-found-sec-content .content-oops{
        font-size: 20px;
    }
}
 /******** Captcha Show and Hide  */
 
.grecaptcha-badge{
    visibility: hidden;
}




